import React, { Suspense, useEffect, useState } from 'react'
import {
} from 'actions/appActions'
import {
    CloseButton, Spinner,
} from 'components'
import { SlideShow } from 'components/SlideShow'
import { useAppSelector, useExclusiveMediaPermissions, useHandleExclusive } from 'app/hooks'
import { MediaType } from 'app/types'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
import { use } from 'chai'
const Spin = React.lazy(() => import('components/Spin/Spin'))

interface LightboxDialogProps {
    app: AppData,
    organization?: OrganizationData,
    onClose?: () => void,
    animation?: string,
    // Pass along
    media: string[] | { type: MediaType, link: string },
    startIndex?: number,
    mediaOptions?: { [key: string]: string },
}

export function LightboxDialog(props: LightboxDialogProps) {
    const {
        app,
        organization,
        onClose,
        animation,
        media,
    } = props

    const screen = useAppSelector((state: RootState) => state.app.screen)
    const allMedia = useAppSelector((state: RootState) => state.app.media)
    const [altMedia, setAltMedia] = useState(null)
    const exclusiveMediaPermissions = useExclusiveMediaPermissions()
    const handleExclusive = useHandleExclusive()
    const [startIndex, setStartIndex] = useState(props.startIndex || 0)

    function handleIndex(x) {
        setStartIndex(x)
    }

    function handleAltMedia(m) {
        if(!exclusiveMediaPermissions(app, m)) {
            handleExclusive()
        }
        setAltMedia(m)
    }

    function handleClose(e) {
        e?.stopPropagation()
        if (altMedia) {
            setAltMedia(null)
            return
        }
        if (onClose) {
            onClose()
        }
    }
    let finalMedia = Array.isArray(media) && media.length == 1 ? media[0] : media
    if (typeof finalMedia == 'string' && finalMedia in allMedia) {
        finalMedia = allMedia[finalMedia]
    } else if (typeof finalMedia == 'object' && 'mediaId' in finalMedia) {
        finalMedia = allMedia[finalMedia.mediaId]
    }
    if (altMedia) {
        finalMedia = altMedia
    }
    const finalType = finalMedia?.mediaTypeId//nalMedia?.type || finalMedia?.mediaTypeId

    if (Array.isArray(finalMedia) || typeof finalMedia == 'string') {
        return <React.Fragment>
            <CloseButton fadeIn onClick={handleClose} />
            <div className={`lightbox ${animation}`}>
                <SlideShow padding={screen.isMobile ? 0 : 100} onAltMedia={handleAltMedia} onIndex={handleIndex} {...props} startIndex={startIndex} />
            </div>
        </React.Fragment>
    } else if ((app || organization) && finalType != null) {
        let mediaElement = null
        switch (finalType) {
            case MediaType.Tour:
                let modelhome = app.modelhomes.find((x) => {
                    if (finalMedia.id) {
                        return x.mediaId == finalMedia.id
                    } else {
                        return x.link == finalMedia.link
                    }
                })

                if (modelhome) {
                    mediaElement = <ModelhomeView app={app} dataLink={modelhome.link} inPrompt />
                }
                break
            case MediaType.Spin:
                const spin = app.spins.find((x) => x.views.find((y) => y.mediaId == finalMedia.id))
                if (spin) {
                    mediaElement = <Suspense fallback={<Spinner />}>
                        <Spin
                            app={app}
                            spinLink={spin.link}
                            viewLink={spin.views.find((x) => x.mediaId == finalMedia.id)?.link}
                            disclaimer={true}
                            inPrompt />
                    </Suspense>
                }
                break
            default:
            case MediaType.Image:
                const mediaProps = { ...props, media: [finalMedia.id] }
                mediaElement = <SlideShow padding={screen.isMobile ? 0 : 100}  {...mediaProps} />
                break
        }

        return <div className={`lightbox ${animation} full`}>
            <CloseButton fadeIn onClick={handleClose} />
            {mediaElement}
        </div>
    }
}
