import React, { useEffect, useState, useRef, Suspense } from 'react'
import {
    showPrompt,
    addProjectMedia,
    resolvePrompt,
} from 'actions/appActions'
import {
    CloseButton,
    Media,
    TopBar,
    Button,
    IconButton,
    Spinner,
    Input,
    DropdownMenu,
    MediaTile,
    Message,
} from 'components'
import {
    ErrorMessage,
    PromptOptions,
    PromptType,
    MediaType,
    ThumbSize,
    MediaTagData,
} from 'app/types'
import { addMedia, addMediaFiles, removeMedia, pollJob, startJob, updateMedia, addMediaTag, removeMediaTag, generateMediaThumbnails, exportMediaFile } from 'actions/adminActions'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { getSetCached } from 'app/state'
import { FileUploader } from 'react-drag-drop-files'
import { parseData } from 'helpers/requestHandler'
import { getMediaLink } from 'helpers/media'
import SpinPreview from 'components/Spin/SpinPreview'
import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'
import { ModelhomeView } from 'views/ModelhomePage/ModelhomeView'
import { Checkbox } from 'components/Core/Checkbox'
import { current } from '@reduxjs/toolkit'
import { icons } from 'app/constants'

const MAX_FILES = 15

interface MediaFieldEditorProps {
    value: string
    onChange: () => void,
    onSelect: () => void,
    onDelete: () => void,
}

function getSegment(app, builder, organization) {
    if (app) {
        return { app }
    }
    if (builder) {
        return { builder }
    }
    if (organization) {
        return { organization }
    }
    return {}
}

export function MediaFieldEditor(props: MediaFieldEditorProps) {
    const { onChange, onSelect, onDelete } = props
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(props.value)

    function handleEdit() {
        onChange(value)
        setEditing(false)
    }

    function handleCancel() {
        setValue(props.value)
        setEditing(false)
    }

    function handleSelect() {
        onSelect()
    }

    if (editing) {
        return <div className="media-field-editor editing">
            <Input value={value} onChange={setValue} onSubmit={handleEdit} />
            <IconButton icon={icons.check} onClick={handleEdit} />
            <IconButton icon={icons.times} onClick={handleCancel} />
        </div>
    }
    return <div className="media-field-editor" onClick={onSelect ? handleSelect : () => setEditing(true)}>
        <span>{value}</span>
        <IconButton icon={icons.pen} noBg onClick={(e) => { e.stopPropagation(); setEditing(true) }} />
    </div>
}

interface MediaTagEditorProps {
    tags: MediaTagData[],
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    value: string,
    onSelect: () => void,
    onChange: () => void,
    onDelete: () => void,
    isDialog: boolean,
}

export function MediaTagEditor(props: MediaTagEditorProps) {
    const { tags, app, builder, organization, value, onSelect, onChange, onDelete, isDialog } = props
    const config = useAppSelector((state: RootState) => state.admin.config)
    if (!tags && !config) {
        return null
    }
    const tagMap = tags ? tags.reduce((acc, x) => ({ ...acc, [x.id]: x.name }), {}) : {}
    const items = tags.map((x) => {
        if (isDialog && false) {
            return { value: x.id, text: x.name }
        } else {
            return {
                value: x.id, element: <div className="group">
                    <Button className={`${value && value.includes(x.id) ? 'selected' : ''}`} onClick={() => handleSelect(x.id)}>{x.name}</Button>
                    <IconButton noBg icon={icons.trash} onClick={(e) => handleDelete(x)}></IconButton>
                </div>
            }
        }
    })
    if (value != null) {
        items.push({ value: null, text: 'No Tag' })
    }

    function handleSelect(x) {
        onSelect(x)
    }

    function handleChange(x) {
        onChange({ name: x, id: null })
    }

    function handleDelete(x) {
        onDelete(x)
    }

    return <DropdownMenu
        className="media-tag-editor"
        text={value && value in tagMap ? tagMap[value] : 'Tag'}
        value={value}
        items={items}
        onChange={handleSelect}
        onAdd={handleChange}
        addPlaceholder="Add Tag" />
}

interface MediaEditorProps {
    tags: MediaTagData[],
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    media: MediaData,
    onChange: () => void,
    onDelete: () => void,
    onAddTag: () => void,
    onRemoveTag: () => void,
    isDialog: boolean,
}
function MediaEditor(props: MediaEditorProps) {
    const { tags, app, builder, organization, media, onChange, onDelete, onAddTag, onRemoveTag, isDialog } = props
    const [deleting, setDeleting] = useState(false)
    const dispatch = useAppDispatch()
    const [updateKey, setUpdateKey] = useState(Date.now())
    const previewRef = useRef()
    if (!media) {
        return null
    }
    const ModelPreview = media.mediaTypeId == MediaType.Model ? React.lazy(() => import('components/Spin/ModelPreview')) : null

    if (!media) {
        return <h3>Missing media</h3>
    }

    let fullscreen = previewRef.current != null && fnc.isFullscreen() == previewRef.current



    function handleDelete() {
        if (onDelete) {
            onDelete(media.id)
        }
        setDeleting(false)
    }

    function handleLightbox() {
        dispatch(showPrompt({ type: PromptType.Lightbox, app: app, media: [media.id] }))
    }

    function handleView() {
        if (previewRef.current) {
            fnc.toggleFullscreen(previewRef.current)
            setUpdateKey(Date.now())
        }
    }

    function handleDownload() {
        switch (media.mediaTypeId) {
            case MediaType.Spin:
                window.open(getMediaLink(media.link + '.zip', { app }), '_blank').focus()
            default:
                window.open(getMediaLink(media.link, { app }), '_blank').focus()
        }
    }

    async function handleThumbnails() {
        await dispatch(generateMediaThumbnails({ media, options: getSegment(app, builder, organization) }))
        setUpdateKey(Date.now())
        // onChange({...media})
    }

    async function handleUploadThumbnail() {
        // Open file dialog and pick file
        const file = await fnc.pickFile()
        if (file) {
            await dispatch(generateMediaThumbnails({ media, options: getSegment(app, builder, organization), file }))
            setUpdateKey(Date.now())
        }
    }

    async function handleExport() {
        const ret = await dispatch(exportMediaFile({ media, options: getSegment(app, builder, organization) }))
        setUpdateKey(Date.now())
    }


    function handleName(x) {
        const newMedia = { ...media }
        newMedia.name = x
        onChange(newMedia)
    }
    function handleTag(x) {
        const newMedia = { ...media, mediaTagId: x }
        onChange(newMedia)
    }

    async function addTag(x) {
        const ret = await dispatch(addMediaTag({ data: x, options: { app, builder, organization } }))
        const newMedia = { ...media, mediaTagId: ret.payload.mediaTag.id }
        onAddTag(ret.payload.mediaTag)
        onChange(newMedia)
    }

    function deleteTag(tag) {
        dispatch(showPrompt({ ...PromptOptions.DeleteData, title: `Delete tag ${tag.name}?` }))
            .then((x) => {
                if (x.payload) {
                    dispatch(removeMediaTag({ data: tag, options: { app, organization, builder } }))
                    onRemoveTag(tag)
                    if (media.mediaTagId == x.id) {
                        const newMedia = { ...media, mediaTagId: null }
                        onChange(newMedia)
                    }
                }
            })
    }
    function getOptions() {
        return <React.Fragment>
            {deleting && <div className="row media-selected-item-prompt">
                <span>
                    Delete?
                    <Button onClick={handleDelete}>Yes</Button>
                    <Button onClick={() => setDeleting(false)}>No</Button>
                </span>
            </div>}
            {!deleting && <IconButton icon={icons.trash} onClick={() => setDeleting(true)} />}
            <IconButton icon={`${fullscreen ? icons.compressAlt : icons.expandAlt}`} onClick={handleView} />
            {media.mediaTypeId != MediaType.Tour && <IconButton icon={icons.download} onClick={handleDownload} />}
            {media.mediaTypeId == MediaType.Image || media.mediaTypeId == MediaType.Video && <IconButton icon={icons.redoAlt} onClick={handleThumbnails} />}
            {media.mediaTypeId == MediaType.Video && <IconButton icon={icons.upload} onClick={handleUploadThumbnail} />}
            {media.mediaTypeId == MediaType.File && media.link.includes('kml') && <IconButton icon={icons.export} onClick={handleExport} />}
        </React.Fragment>
    }

    function getPreview() {
        switch (media.mediaTypeId) {
            default:
            case MediaType.Image:
                return <Media key={`${media.dateModified}${updateKey}`} app={app} builder={builder} organization={organization} mediaId={media.id} fallback thumb={!fullscreen} thumbSize={!fullscreen ? ThumbSize.Large : null} onClick={handleLightbox} hoverShow />
            case MediaType.Video:
                return <Media key={`${media.dateModified}${updateKey}`} app={app} builder={builder} organization={organization} mediaId={media.id} fallback thumb={!fullscreen} thumbSize={!fullscreen ? ThumbSize.Large : null} />
            case MediaType.Model:
                return <Suspense fallback={<Spinner />}><ModelPreview media={media} app={app} /></Suspense>
            case MediaType.Spin:
                return <SpinPreview media={media} app={app} fullscreen={fullscreen} />
            case MediaType.Tour:
                return <ModelhomeView app={app} mediaId={media.id} disclaimer={false} />
        }
    }

    function getMediaDetails() {
        switch (media.mediaTypeId) {
            case MediaType.Tour:
                return <React.Fragment>
                    <span>C: {media.dateCreated}</span>
                    <span>M: {media.dateModified}</span>
                </React.Fragment>
                break
            default:
                return <React.Fragment>
                    <span>{Math.floor(100 * media.size / (1024 * 1024)) / 100}MB</span>
                    {media.width && media.height && <span>{media.width}x{media.height}</span>}
                    <span>C: {media.dateCreated}</span>
                    <span>M: {media.dateModified}</span>
                    {media.fileCount && media.fileCount > 0 && <span>#: {media.fileCount}</span>}
                </React.Fragment>
        }
    }

    const wrapperStyle = {}
    if (fullscreen) {
        wrapperStyle.padding = 0
        wrapperStyle.position = 'relative'
    } else if (media.mediaTypeId == MediaType.Spin) {
        wrapperStyle.paddingBottom = `${100 * media.height / media.width}%`
    }


    return <div className="media-selected-item">
        <div className="row media-wrapper" ref={previewRef} style={wrapperStyle}>
            {getPreview()}
        </div>
        <div className="row media-selected-item-info">
            <div className="row media-selected-item-options">
                {getOptions()}
                <MediaTagEditor tags={tags} app={app} builder={builder} organization={organization} value={media.mediaTagId} onSelect={handleTag} onChange={addTag} onDelete={deleteTag} isDialog={isDialog} />
            </div>
            <div className="row" style={{ width: '100%' }}>
                <MediaFieldEditor value={media.name} onSubmit={handleName} onChange={handleName} />
            </div>
            <span>{media.link}</span>
            {getMediaDetails()}
        </div>
    </div>
}

interface MediaDialogProps {
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    onClose?: () => void,
    onSelect: () => void,
    onDisableClose: () => void,
    animation?: string,
    selected: string,
    multi: boolean,
    upload: boolean,
}
const fileTypes = {
    [MediaType.Image]: ['jpg', 'png', 'gif', 'svg'],
    [MediaType.Model]: ['obj'],
    [MediaType.Spin]: ['zip'],
    [MediaType.File]: ['pdf', 'kml'],
}
const POLL_INTERVAL = 1000
export function MediaDialog(props: MediaDialogProps) {
    const {
        app,
        builder,
        organization,
        onClose,
        onSelect,
        onDisableClose,
        animation,
        selected,
        multi,
    } = {
        multi: false,
        ...props,
    }
    let mediaTypeId = [MediaType.Image, MediaType.Video, MediaType.Tour, MediaType.Spin, MediaType.File]
    if (props.mediaTypeId != null) {
        mediaTypeId = props.mediaTypeId
    }
    const config = useAppSelector((state: RootState) => state.admin.config)
    let allMedia = useAppSelector((state: RootState) => state.app.media)

    const [tags, setTags] = useState([])
    const [selectedMedia, setSelectedMedia] = useState((() => {
        if (!selected) {
            return {}
        }
        if (Array.isArray(selected)) {
            return selected.reduce((acc, x) => ({ ...acc, [x]: x }), {})
        }
        return { [selected]: selected }
    })())
    const [selectedMap, setSelectedMap] = useState({...selectedMedia})
    const [uploading, setUploading] = useState(props.upload)
    const [deleting, setDeleting] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(null)
    const [jobProgress, setJobProgress] = useState(null)
    const [job, setJob] = useState(null)
    const [error, setError] = useState(null)
    const [media, setMedia] = useState({})
    const [mediaCount, setMediaCount] = useState(null)
    const [tagFilter, setTagFilter] = useState(null)
    const [searchFilter, setSearchFilter] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState(false)
    const [currentMediaTypeId, setCurrentMediaTypeId] = useLocalStorage('mediaTypeId', mediaTypeId || MediaType.Image)
    const [resize, setResize] = useState(false)
    const dispatch = useAppDispatch()
    const isDialog = onClose != null
    const listRef = useRef()

    useEffect(() => {
        let newTags = []
        if (app) {
            newTags = app.meta.mediaTags
        } else if (builder) {
            newTags = builder.mediaTags
        } else if (organization) {
            newTags = organization.mediaTags
        }
        setTags(newTags)
    }, [app?.mediaTags, builder?.mediaTags, organization?.mediaTags])


    function handleAddTag(x) {
        setTags([...tags, x])
    }
    function handleRemoveTag(x) {
        setTags(tags.filter(y => y.id != x.id))
    }

    useEffect(() => {
        if (Array.isArray(mediaTypeId) && !mediaTypeId.includes(currentMediaTypeId)) {
            setCurrentMediaTypeId(mediaTypeId[0])
        } else if (!Array.isArray(mediaTypeId) && currentMediaTypeId != mediaTypeId) {
            if (Array.isArray(mediaTypeId)) {
                if (mediaTypeId.length > 0) {
                    setCurrentMediaTypeId(mediaTypeId[0])
                }
            } else {
                setCurrentMediaTypeId(mediaTypeId)
            }
        }
    }, [mediaTypeId])

    useEffect(() => {
        if (currentMediaTypeId != null) {
            return
        }

        let newMediaType = null
        if (selected) {
            let selMedia = null
            if (Array.isArray(selected) && selected.length > 0) {
                selMedia = allMedia[selected[0]]
            } else if (!Array.isArray(selected)) {
                selMedia = allMedia[selected]
            }
            newMediaType = selMedia?.mediaTypeId
        } else if (!newMediaType && mediaTypeId) {
            if (Array.isArray(mediaTypeId)) {
                newMediaType = mediaTypeId[0]
            } else {
                newMediaType = mediaTypeId
            }
        }

        setCurrentMediaTypeId(newMediaType)
    }, [selected])

    useEffect(() => {
        getSetCached('media_search_filter', searchFilter, setSearchFilter, '')
    }, [searchFilter])

    useEffect(() => {
        getSetCached('media_tag_filter', tagFilter, setTagFilter, '')
    }, [tagFilter])

    useEffect(() => {
        if (!currentMediaTypeId) {
            return
        }
        const newMedia = Object.keys(allMedia).filter(x => {
            const m = allMedia[x]
            if (m.link.startsWith('download-')) {
                return false
            }
            if (!selectedFilter && m.mediaTypeId != currentMediaTypeId) {
                return false
            }
            if (m.link.includes('-download')) {
                return false
            }

            if (mediaTypeId == MediaType.EmailResource) {
                return true
            } else {
                if (app) {
                    return m.appId == app.meta.id
                } else if (builder) {
                    return m.builderId == builder.id
                } else if (organization) {
                    return m.organizationId == organization.id
                } else {
                    return m.appId == null
                }
            }
        }).map((x) => allMedia[x]).reduce((acc, x) => ({ ...acc, [x.id]: x }), {})
        setMedia(newMedia)
        setMediaCount(Object.keys(newMedia).length)
    }, [allMedia, currentMediaTypeId, selectedFilter])

    useEffect(() => {
        if (onDisableClose) {
            onDisableClose(uploadProgress != null)
        }
    }, [uploadProgress])

    useEffect(() => {
        if (!job || !uploading || error) {
            return
        }

        const interval = setInterval(() => {
            dispatch(pollJob(job.id))
                .then((x) => {
                    if (x.payload && x.payload.success) {
                        // Is job done? 
                        const { job } = x.payload
                        handleJobProgress(job.progress)
                        if (job.status == 'failed') {
                            setError({ error: job.data })
                            setJob(null)
                        } else if (job.status == 'complete') {
                            clearInterval(interval)

                            const data = parseData(JSON.parse(job.data))
                            const { media: newMedia, failures } = data

                            dispatch(addProjectMedia(newMedia))

                            // Add media to selected
                            let newSelected = {}
                            if (multi) {
                                newSelected = { ...selectedMedia }
                                Object.keys(newMedia).forEach((y) => {
                                    newSelected[newMedia[y].id] = newMedia[y].id
                                })
                                setSelectedMedia(newSelected)
                            } else if (Object.keys(newMedia).length > 0) {
                                const first = Object.keys(newMedia)[0]
                                newSelected[newMedia[first].id] = newMedia[first].id
                                setSelectedMedia(newSelected)
                            }

                            // Finish
                            if (failures && Object.keys(failures).length > 0) {
                                setError({ failures })
                                setJob(null)
                            } else {
                                handleJobComplete(newSelected)
                            }
                        }
                        // Otherwise keep checking
                    } else {
                        if (x.payload && x.payload.message) {
                            setError({ error: x.payload.message })
                        } else {
                            setError({ error: ErrorMessage.MediaUploadError })
                        }
                        setJob(null)
                    }
                }).catch(logger.error)
        }, POLL_INTERVAL)

        return () => {
            clearInterval(interval)
        }
    }, [job, error, uploading])

    function handleCurrentMediaType(x) {
        setCurrentMediaTypeId(x)
        // setSelectedMedia(multi ? [] : null)
    }

    function sortMedia(a, b) {
        const mA = media[a]
        const mB = media[b]
        const dateA = new Date(mA.dateCreated)
        const dateB = new Date(mB.dateCreated)
        // Check whether part of original selected map
        const selectedA = selectedMap[a]
        const selectedB = selectedMap[b]
        if (selectedA && !selectedB) return -1 // selectedA comes first
        if (!selectedA && selectedB) return 1 // selectedB comes first
        return dateB - dateA
    }

    function handleClose() {
        if (uploadProgress == null || error) {
            onClose()
        }
    }

    function handleSelectMedia(x, e, force = false) {
        let newMedia = { ...selectedMedia }
        if (multi || (!isDialog && (e.ctrlKey || e.shiftKey))) {
            if (e.shiftKey) {
                const mediaKeys = Object.keys(media)
                // If shift, find min index and re-build selection to all items in between
                const indexMap = mediaKeys.sort(sortMedia).reduce((acc, x, ix) => ({ ...acc, [media[x].id]: ix }), {})
                const sorted = Object.keys(newMedia).sort((a, b) => {
                    return indexMap[a] - indexMap[b]
                })
                let min = indexMap[sorted[0]]
                let max = indexMap[sorted[sorted.length - 1]]

                const clickedIndex = indexMap[x.id]
                if (clickedIndex < min) {
                    min = clickedIndex
                } else if (clickedIndex > min) {
                    max = clickedIndex
                }

                // Select all between min and max
                newMedia = {}
                for (let i = min; i <= max; i += 1) {
                    newMedia[media[mediaKeys[i]].id] = media[mediaKeys[i]].id
                }
            } else if (x.id in newMedia) {
                delete newMedia[x.id]
            } else {
                newMedia[x.id] = x.id
            }
        } else {
            if (x.id in newMedia) {
                delete newMedia[x.id]
            } else {
                newMedia = { [x.id]: x.id }
            }
        }
        setSelectedMedia(newMedia)
        if (force) {
            handleSelect()
        }
    }

    function handleStartUpload() {
        setUploadProgress(null)
        setJobProgress(null)
        setError(null)
        setUploading(true)
    }

    function handleUploadProgress(x) {
        setUploadProgress(100 * x.loaded / x.total)
    }

    function handleJobProgress(x) {
        setJobProgress(x)
    }

    function handleJobComplete(x) {
        setJob(null)
        setJobProgress(100)

        if (props.upload) {
            setTimeout(() => {
                handleSelect(x)
            }, 1000)
        } else {


            // Finish
            setTimeout(() => {
                setUploadProgress(null)
                setJobProgress(null)
                setUploading(false)
            }, 1000)
        }
    }


    function handleAddTour(link) {
        if (Object.values(allMedia).find((x) => {
            return x.mediaTypeId == MediaType.Tour && x.link == link
        }) != null) {
            setError({ error: 'Tour has already been added' })
            return
        }

        // Create new tour media and add media
        const tourMedia = {
            tempId: fnc.uniqueId(),
            id: null,
            link,
            name: link,
            mediaTypeId: MediaType.Tour,
        }
        const options =
            dispatch(addMedia({ media: tourMedia, options: getSegment(app, builder, organization) }))
                .then((x) => {
                    if (x.payload) {
                        dispatch(addProjectMedia([x.payload.media]))
                    }
                })

        setUploading(false)
    }

    async function handleAddVideo(link) {
        if (Object.values(allMedia).find((x) => {
            return x.mediaTypeId == MediaType.Video && x.app?.meta.id == app?.meta.id && x.link == link
        }) != null) {
            setError({ error: 'Video has already been added' })
            return
        }

        // Create new video media and add media
        const videoMedia = {
            tempId: fnc.uniqueId(),
            id: null,
            link,
            name: link,
            mediaTypeId: MediaType.Video,
        }
        setUploadProgress(0)
        setJobProgress(0)
        await new Promise((resolve) => setTimeout(resolve, 100))
        setUploadProgress(50)
        setJobProgress(50)
        await dispatch(addMedia({ media: videoMedia, options: getSegment(app, builder, organization) }))
            .then((x) => {
                if (x.payload) {
                    dispatch(addProjectMedia([x.payload.media]))
                }
            })
        setUploadProgress(100)
        setJobProgress(100)
        setTimeout(() => {
            setUploadProgress(null)
            setJobProgress(null)
            setUploading(false)
        }, 1000)
    }

    function handleAddMedia(files) {
        setUploadProgress(0)
        setError(null)
        dispatch(addMediaFiles({ files: Array.from(files).slice(0, MAX_FILES), onUploadProgress: handleUploadProgress, options: { ...getSegment(app, builder, organization), mediaTypeId: currentMediaTypeId, resize } }))
            .then((x) => {
                if (x.payload && x.payload.success) {
                    const { job } = x.payload
                    setUploadProgress(100)

                    setJob(job)
                    dispatch(startJob(job.id))
                } else {
                    setError({ error: x.payload.message })
                }
            })
    }

    async function handleDelete(x = null) {
        if (x && x in selectedMedia) {
            setDeleting(true)
            // delete media[x]
            delete selectedMedia[x]
            setSelectedMedia({ ...selectedMedia })
            await dispatch(removeMedia({ media: [x], options: { app, builder, organization } }))
            setDeleting(false)
        }
    }

    async function handleDeleteAll() {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete all selected media?', subtitle: 'Cannot be undone' }))
            .then(async (x) => {
                if (x.payload) {
                    setDeleting(true)
                    await dispatch(removeMedia({ media: [Object.keys(selectedMedia)], options: { app, builder, organization } }))
                    setDeleting(false)
                    setSelectedMedia({})
                }
            })
    }

    function handleChange(x) {
        dispatch(updateMedia({ data: x, options: { app, builder, organization } }))
    }

    function handleSelect(_selectedMedia = null) {
        if (_selectedMedia == null) {
            _selectedMedia = selectedMedia
        }
        if (isDialog && !onSelect) {
            dispatch(resolvePrompt(Object.keys(_selectedMedia)))
        }

        if (onSelect) {
            onSelect(Object.keys(_selectedMedia))
        }

        if (onClose) {
            onClose()
        }
    }

    async function handleTagSelected(tagId) {
        const keys = Object.keys(selectedMedia)
        for (let i = 0; i < keys.length; i += 1) {
            const m = media[keys[i]]
            if (m && m.mediaTagId != tagId) {
                await dispatch(updateMedia({ data: { ...m, mediaTagId: tagId }, options: { app, builder, organization } }))
            }
        }
    }

    function handleTagFilter(tag) {
        if (tagFilter && tagFilter.length > 0 && tagFilter === tag) {
            setTagFilter('')
        } else {
            setTagFilter(tag)
        }
    }


    function getMedia() {
        let scrolledTo = false
        let items = Object.keys(media).filter((x) => {
            const m = media[x]
            if (selectedMedia && m.id in selectedMedia) {
                return true
            }

            if (tagFilter) {
                return m.mediaTagId == tagFilter || (m.mediaTagId == null && tagFilter == 'untagged')
            }

            if (searchFilter && searchFilter.length > 0) {
                return m.name.includes(searchFilter)
            }
            if (selectedFilter && !(selectedMedia && m.id in selectedMedia)) {
                return
            }
            return true
        })
            .sort(sortMedia)
            .map((x) => {
                const m = media[x]
                if (!(m.id in allMedia)) {
                    return null
                }
                const isSelected = !scrolledTo && Array.isArray(selected) ? selected.includes(m.id) : m.id == selected
                if (isSelected) {
                    scrolledTo = true
                }
                // if(currentMediaTypeId == MediaType.File) {
                // }
                return <MediaTile key={`${m.id}${m.name}`} app={app} builder={builder} organization={organization} media={m} onClick={handleSelectMedia} scrollTo={isSelected} selected={selectedMedia && m.id in selectedMedia} isDialog={isDialog} listRef={listRef} />
            })

        if (currentMediaTypeId === MediaType.File) {
            return <table ref={listRef} className="media-list scrollable">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date Modified</th>
                        <th>Date Created</th>
                        {isDialog && <th>Selected</th>}
                    </tr>
                </thead>
                <tbody>
                    {items}
                </tbody>
            </table>
        }
        return <div className="media-list scrollable" ref={listRef}>
            {items}
        </div>
    }

    function getSelected() {
        if (!selectedMedia) {
            return null
        }
        const count = Object.keys(selectedMedia).length
        return <React.Fragment>
            {Object.keys(selectedMedia).map((x) => {
                return <MediaEditor key={x} tags={tags} app={app} builder={builder} organization={organization} media={media[x]} onDelete={handleDelete} onChange={handleChange} onAddTag={handleAddTag} onRemoveTag={handleRemoveTag} isDialog={isDialog} />
            })}
            {count == 0 && <h4>Select Media</h4>}
            <div className="row media-options">
                {(isDialog || onSelect) && <Button icon={icons.check} secondary onClick={() => handleSelect()}>Select Media</Button>}
                {count > 1 && <DropdownMenu text="Tag All" onChange={handleTagSelected} items={[...tags.map((x) => ({ text: x.name, value: x.id })), { value: null, text: 'No Tag' }]} />}
                {count > 1 && <Button onClick={handleDeleteAll}>Delete All</Button>}
            </div>
        </React.Fragment>
    }

    function getFilters() {
        let text = ''
        if (tagFilter) {
            if (tagFilter === 'untagged') {
                text = 'Tag: untagged'
            } else {
                const tag = tags.find((x) => x.id == tagFilter)
                if (tag) {
                    text = `Tag: ${tag.name}`
                } else {
                    text = 'Tag not found'
                }
            }
        } else {
            text = 'Tags'
        }
        const items = tags ? tags.map((x) => ({ text: x.name, value: x.id })) : []
        items.push({ text: 'untagged', value: 'untagged' })
        if (tagFilter) {
            items.push({ text: 'clear', value: '' })
        }
        return <React.Fragment>
            {!selectedFilter && Array.isArray(mediaTypeId) && <DropdownMenu text={currentMediaTypeId && currentMediaTypeId != null ? `Media Type: ${MediaType[currentMediaTypeId]}` : 'Select Media Type'} value={currentMediaTypeId} onChange={handleCurrentMediaType} items={mediaTypeId.map((x) => ({ text: MediaType[x], value: x }))} />}
            {searchFilter != null && <Input placeholder="Search" value={searchFilter} onChange={setSearchFilter} clear />}
            <Checkbox value={selectedFilter} onChange={setSelectedFilter}>Selected</Checkbox>
            <DropdownMenu
                text={text}
                onChange={handleTagFilter}
                value={tagFilter}
                items={items} />
        </React.Fragment>
    }

    function getUploader() {
        if (error) {
            return <div className="media-uploader">
                <h3>Error uploading media</h3>
                {error.failures && <span>{error.failures.map((x) => {
                    if ('name' in x) {
                        return `${x.name}: ${JSON.stringify(x.error)}`
                    } else {
                        return JSON.stringify(x)
                    }
                }).join(',')}</span>}
                {error.error && <span>{error.error}</span>}
            </div>
        }
        if (uploadProgress != null) {
            const progress = (uploadProgress != null ? uploadProgress * 0.5 : 0) + (jobProgress != null ? jobProgress * 0.5 : 0)
            return <div className="media-uploader">

                <h3 className={jobProgress == 100 ? 'animate__animated animate__headShake' : ''}>{`${uploadProgress < 100 ? 'Uploading' : (jobProgress < 100 ? 'Processing' : 'Done!')} (${parseInt(progress)}%)`}</h3>
                <Spinner showAfter={0} show={true} />
                <div className="progress" style={{ width: `${progress}%` }} />
            </div>
        }

        switch (currentMediaTypeId) {
            case MediaType.Tour:
                return <div className="file-picker media-picker">
                    <Input placeholder="Enter tour link" autoFocus onSubmit={handleAddTour} submit />
                </div>
            case MediaType.Video:
                return <div className="file-picker media-picker">
                    <Input placeholder="Enter video link" autoFocus onSubmit={handleAddVideo} submit />
                </div>
            default:
                return <div className="file-picker media-picker">
                    <div className="row" style={{ position: 'absolute', zIndex: 100 }} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
                        <Checkbox onChange={setResize} value={resize}>Resize on Upload</Checkbox>
                    </div>
                    <FileUploader classes="drag-and-drop" multiple handleChange={handleAddMedia} name="file" types={fileTypes[currentMediaTypeId]}>
                        <h3> Click or drag to upload files (max {MAX_FILES} files) </h3>
                    </FileUploader>
                </div>
        }
    }
    return <React.Fragment>
        <div className={`media-browser ${animation ? animation : ''}`} onMouseDown={(e) => e.stopPropagation()}>
            <TopBar>
                <div className="options left">
                    <h4>{app ? `${app.meta.name} ${MediaType[currentMediaTypeId]?.toReadable()}s` : `${MediaType[currentMediaTypeId]?.toReadable()}`}</h4>
                </div>
                <div className="options right">
                    {!uploading && getFilters()}
                    {!uploading && <Button alt icon={icons.plus} onClick={handleStartUpload}>Add {MediaType[currentMediaTypeId]?.toReadable()}</Button>}
                    {uploading && !props.upload && <Button alt icon={icons.times} onClick={() => setUploading(false)}>Cancel</Button>}
                    {onClose && <CloseButton fadeIn onClick={handleClose} />}
                </div>
            </TopBar>
            <div className="row">
                {mediaCount > 0 && <React.Fragment>
                    <div className="column">
                        {getMedia()}
                    </div>
                    <div className="column col-3 media-selected scrollable">
                        {getSelected()}
                    </div>
                </React.Fragment>}
                {mediaCount == 0 && <h3>No {currentMediaTypeId in MediaType ? MediaType[currentMediaTypeId]?.toReadable().toLowerCase() : 'Media'}s added</h3>}
                {uploading && getUploader()}
                {deleting && <div className="media-deleting fadeIn">
                    <Spinner showAfter={0}></Spinner>
                </div>}
            </div>
        </div>
    </React.Fragment>
}
