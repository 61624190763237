import React, { useEffect, useState } from 'react'
import {
    Button, CloseButton,
} from 'components'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    AnalyticsEvent, CustomEventType, ErrorMessage, OrganizationData, PromptOptions,
} from 'app/types'
import { submitBooking, analyticsEvent, showPrompt } from 'actions/appActions'
import { ContentForm } from 'views/AppPage/ContentForm'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { packageFormData } from 'helpers/requestHandler'
import { requestInfoFields } from 'app/constants'

interface RequestInfoProps {
    app: AppData,
    organization: OrganizationData,
    title: string,
    body: string,
    forceOpen?: boolean
    fields: Dict,
    data: Dict,
}

export function RequestInfo(props: RequestInfoProps) {
    const {
        app, organization, title, body, forceOpen, data, promptOptions, ...formOptions
    } = props
    const dispatch = useAppDispatch()
    const user = useAppSelector((state: RootState) => state.user.data)
    const org = useAppSelector((state: RootState) => state.app.organization)

    const [requestingInfo, setRequestingInfo] = useState(forceOpen)
    const [animation, setAnimation] = useState()
    const [submitRef, setSubmitRef] = useState(null)
    const [eventApp, setEventApp] = useState(null)
    const [formConfig, setFormConfig] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(null)
    if (!app && !organization) {
        return null
    }

    let infoTarget = ''
    if (eventApp && eventApp.meta) {
        infoTarget = eventApp.meta.name
    } else if (app) {
        infoTarget = app.meta.name
    } else if (organization) {
        infoTarget = organization.name
    }

    useEffect(() => {
        let newConfig = []
        const newOptions = {
            analyticsEventTypeId: AnalyticsEvent.RequestInformation,
        }

        if (props.fields) {
            newConfig = [...props.fields]
        } else {
            newConfig = requestInfoFields(user, infoTarget)
        }

        if (app && app.meta.hubspotAccessToken) {
            newOptions.submitToHubspot = true
        }
        if (org && org.consent) {
            newConfig.push({
                id: 'consent',
                label: org.consent,
            })
        }
        setFormConfig({ options: newOptions, fields: newConfig })
    }, [])

    useEffect(() => {
        if (requestingInfo) {
            return
        }
        window.addEventListener(CustomEventType.RequestInfo, handleRequestInfo)
        return () => {
            window.removeEventListener(CustomEventType.RequestInfo, handleRequestInfo)
        }
    }, [requestingInfo])

    function handleRequestInfo(e) {
        if (forceOpen) {
            return
        }
        if (e && e.detail && e.detail.app) {
            setEventApp(e.detail.app)
        }

        if (!requestingInfo) {
            setAnimation('animate__animated animate__slideInUp animate__fastest')
            setRequestingInfo(true)
        } else {
            // When from event, re-popup
            if (e) {
                setAnimation('animate__animated animate__slideOutDown animate__fastest')
                setTimeout(() => {
                    setAnimation('animate__animated animate__slideInUp animate__fastest')
                }, 0)
            } else {
                setAnimation('animate__animated animate__slideOutDown animate__fastest')
                setTimeout(() => {
                    setRequestingInfo(false)
                    setEventApp(null)
                }, 200)
            }
        }
    }

    function submitForm(formData) {
        const { finalFormData, promptOptions, finalFormOptions } = packageFormData({ ...formData, ...data }, formConfig, AnalyticsEvent.RequestInformation)
        /*const finalFormData = { ...formData, ...data }
        if (finalFormData.address) {
            finalFormData.address = `${finalFormData.address}, ${finalFormData.city} ${finalFormData.province}, ${finalFormData.postalcode}`
        } else if (finalFormData.city) {
            finalFormData.address = finalFormData.city
        } else {
            finalFormData.address = ''
        }

        if (formData.firstname && formData.lastname) {
            finalFormData.name = `${formData.firstname} ${formData.lastname}`
        }
        finalFormData.optIn = finalFormData.consent == true

        if (!finalFormData.message) {
            const commentKey = Object.keys(finalFormData).find((key) => key.includes('comment'))
            finalFormData.message = [finalFormData[commentKey]]
        }

        const consent = formConfig.find((x) => x.id == 'consent')
        if (consent && !consent.required) {
            finalFormData.message.push(`Opt In To Communication: ${finalFormData.consent ? 'Yes' : 'No'}`)
        }
        finalFormData.message.push(`Request Type: Request Information`)

        finalFormData.analyticsEventTypeId = AnalyticsEvent.RequestInformation
        const finalPromptOptions = PromptOptions.SubmitBooking*/
        dispatch(analyticsEvent(finalFormData.analyticsEventTypeId))
        return Promise.all([
            dispatch(showPrompt(promptOptions)),
            dispatch(submitBooking({ app, data: finalFormData, options: finalFormOptions })),
        ])
            .then(([a, b]) => {
                try {
                    if (!b.payload.success) {
                        throw b.payload.message
                    } else {
                        handleRequestInfo()
                        return true
                    }
                } catch (e) {
                    throw e
                }
                return false
            }).catch((e) => {
                throw ErrorMessage.BookingError
            })
    }
    if (!formConfig?.fields || formConfig?.fields?.length == 0) {
        return null
    }

    return <div id="request-info" className={`request-info ${requestingInfo ? ' requesting' : ''} scrollable show-scroll`}>
        {requestingInfo && <div className={`request-form ${animation}`}>
            <div className="column">
                <div className="row" style={{ pointerEvents: 'none' }}>
                    <div className="column">
                        <h4>{title || 'Request Information'}</h4>
                        {error && <h5 className="error">{error}</h5>}
                        {body && <span className="body"><ReactMarkdown>{body}</ReactMarkdown></span>}
                    </div>
                </div>
                <div className="row">
                    <ContentForm onSubmit={submitForm} onLoad={setLoaded} onError={setError} preventNav={false} submitRef={submitRef} {...formConfig.options} {...formOptions} fields={formConfig.fields} />
                </div>
            </div>
            {!forceOpen && <CloseButton onClick={() => handleRequestInfo()} />}
        </div>}
        <Button tertiary icon="far fa-paper-plane" className={!loaded ? "disabled" : ""} forwardRef={setSubmitRef} onClick={requestingInfo && loaded ? null : handleRequestInfo}>{requestingInfo ? 'Send' : 'Request Information'}</Button>
    </div >
}
